import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ProfileLayout from 'components/layouts/profile/profile.layout';
import { Container } from 'react-bootstrap';
import PaymentForm from '../intra-page-items/_payment.item';

export default function AddNewPayment() {
    const { t } = useTranslation();

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.payment.eyebrowText`)}
            title={t(`pages.profile.payment.title`)}
            heading={t(`pages.profile.payment.heading`)}
        >
            <Container fluid>
                <PaymentForm isInProfile={true} />
            </Container>
        </ProfileLayout>
    );
}
